import TranslationID from "data/locales/id/business.json";
import { ContainerDesktop } from "components/Container";
import ButtonContact from "components/Button/ButtonContact";
import ImageAutoplay from "components/Autoplay/ImageAutoplay";
import Link from "next/link";
import { usePopupStoreFormSales } from "modules/Home/popupStore";
import { DataInfo } from "./HomeInformation";
import { pushDataLayer } from "lib/gtag";
import Images from "next/image";

export const HomeDescription: React.FC = () => {
  const headingSectionDescription = TranslationID["HomeDescription"];
  const headingTranslationButton = TranslationID["Button"];
  const { showPopupYoutube } = usePopupStoreFormSales((state) => ({
    showPopupYoutube: state.showPopupYoutube,
  }));

  const onClickVideo = () => {
    pushDataLayer({ event: "cta-video" });
    showPopupYoutube();
  };

  const handleScroll = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    // first prevent the default behavior
    e.preventDefault();
    // get the href and remove everything before the hash (#)
    const href = e.currentTarget.href;
    const targetId = href.replace(/.*\#/, "");
    // get the element by id and use scrollIntoView
    const elem = document.getElementById(targetId);
    elem?.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "start",
    });
  };

  const renderBusinessChip = (className, title, description) => {
    return (
      <div
        className={`bg-total-pattern rounded-12 shadow-chip_total border border-white p-8 flex flex-col items-center justify-center h-fit ${className}`}
      >
        <div className="font-black text-h4 text-purple-50">{title}</div>
        <div className="font-light text-b1 2xl-max:text-b2">{description}</div>
      </div>
    );
  };

  return (
    <section id="home-description">
      <ContainerDesktop className="pb-72 pt-60 xl-max:pb-60" useAuto>
        <div className="grid grid-cols-2 gap-x-[42px] xl-max:grid-cols-1 xl-max:gap-y-[42px]">
          <div className="flex flex-col gap-20 text-left">
            <h1
              dangerouslySetInnerHTML={{
                __html: headingSectionDescription.title,
              }}
              className="font-black text-h2 lg-max:text-h3"
            ></h1>
            <Link
              //@ts-ignore
              href={"#home-packets"}
              onClick={handleScroll}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: headingSectionDescription.description,
                }}
                className="font-light text-h4 lg-max:text-b1"
              ></div>
            </Link>

            <div className="flex justify-start items-center gap-8 sm-max:flex-col sm-max:items-start">
              {/* <ButtonScheduleDemo
                className="!h-48 sm-max:w-full"
                wrapperClass={"sm-max:w-full"}
                id="cta-top"
              /> */}
              <ButtonContact
                className=" !h-48 sm-max:w-full"
                id="cta-top"
                inputStep={1}
              />
            </div>
            <div className="mx-16 mt-16">
              <h3>Verified by:</h3>
              <Images
                src={"/images/ecatalog.png"}
                alt="ecatalog"
                width={180}
                height={70}
              />
            </div>
          </div>

          <div className="relative cursor-pointer" onClick={onClickVideo}>
            <ImageAutoplay
              dataImages={[
                "https://s.gokampus.com/business/gk-business-3.jpg",
                "https://s.gokampus.com/business/gk-business-2.png",
              ]}
              className="rounded-16 border-8 border-white w-full h-full"
            />
            <div className="absolute z-1 place-items-center top-0 w-full h-full grid grid-cols-6 xl-max:gap-x-16 sd-max:!gap-x-8">
              {renderBusinessChip(
                "col-start-4 col-end-6 absolute z-10 -top-[30px] xl-max:bottom-0 xl-max:hidden",
                DataInfo.courses,
                headingTranslationButton.courseTotal
              )}

              <div className="col-start-1 col-end-7"></div>
              <div className="col-start-1 col-end-7"></div>

              {renderBusinessChip(
                "col-start-1 col-end-2 absolute z-10 -bottom-[40px] -left-[30px]  xl-max:left-0 xl-max:-bottom-80 xl-max:col-end-3 xl-max:w-full",
                DataInfo.partners,
                headingTranslationButton.partnerTotal
              )}

              {renderBusinessChip(
                "hidden xl-max:flex xl-max:absolute z-10 xl-max:-bottom-80 xl-max:col-start-3 xl-max:col-end-5 xl-max:w-full",
                DataInfo.courses,
                headingTranslationButton.courseTotal
              )}

              {renderBusinessChip(
                "col-start-6 col-end-7 absolute z-10 -bottom-58 -right-12 xl-max:-bottom-80 xl-max:-right-0 xl-max:col-start-5 xl-max:w-full",
                DataInfo.learners,
                headingTranslationButton.learnerTotal
              )}

              {/* YT Icon */}
              <img
                src="https://static.vecteezy.com/system/resources/thumbnails/023/986/704/small/youtube-logo-youtube-logo-transparent-youtube-icon-transparent-free-free-png.png"
                width={100}
                className="z-10 col-start-3 col-end-5 row-start-2"
                alt="Play icon"
              />
            </div>
          </div>
        </div>
      </ContainerDesktop>
    </section>
  );
};
