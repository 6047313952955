import createStore from "zustand";

export type LearningHourStore = {
  // state
  learningHour: string;

  // actions
  setLearningHour: (item: string) => void;
};

export const useLearningHourStore = createStore<LearningHourStore>((set) => ({
  // state
  learningHour: "",

  // actions
  setLearningHour(item) {
    set({ learningHour: item });
  },
}));
