import TranslationID from "data/locales/id/business.json";
import { ContainerDesktop } from "components/Container";
import { Switch } from "components/Switch";
import { useState } from "react";
import { IconCheckWithOuter } from "components/Icon/IconCheckWithOuter";
import { usePacketStore } from "./packetStore";
import HomeTable from "./HomeTable";
import { HomePacketCards } from "./HomePacketCards";
import { Toggle } from "components/Toggle";

export const HomePackets: React.FC = () => {
  const headingSectionPackets = TranslationID["HomePackets"];
  // const [selectedType, setSelectedType] = useState("Tahun");
  // const [isOpen, setIsOpen] = useState(false);
  const [types, setTypes] = useState([
    {
      label: "Bulanan",
      value: "Bulan",
      selected: false,
      child: null,
    },
    {
      label: "Tahunan",
      value: "Tahun",
      selected: true,
      child: (
        <div className="bg-white flex justify-center items-center gap-2 px-6 py-2 rounded-12 text-orange-50 font-medium text-c1">
          <IconCheckWithOuter />
          <div>Hemat 18%</div>
        </div>
      ),
    },
  ]);

  const { setSelectedType, isIncludeCertificate, setIncludeCertificate } = usePacketStore((state) => ({
    setSelectedType: state.setSelectedType,
    isIncludeCertificate: state.isIncludeCertificate,
    setIncludeCertificate: state.setIncludeCertificate,
  }));

  return (
    <section id="home-packets">
      <ContainerDesktop className="py-52 sm-max:py-36" useAuto>
        <div className="flex flex-col gap-24 justify-center text-center">
          <div className="grid gap-8">
            <h2 className="text-h3 font-black text-neutral-80 sm-max:text-h4">
              {headingSectionPackets.title}
            </h2>

            <h3 className="text-h4 font-light text-neutral-70 sm-max:text-b1">
              {headingSectionPackets.description}
            </h3>
          </div>

          <div className="flex flex-col gap-24 justify-center text-center">
            <div className="flex flex-wrap gap-8 justify-center items-center">
              <Switch
                data={types}
                onSelect={(e) => {
                  setSelectedType(e);
                  setTypes(
                    types.map((x) =>
                      x.value == e
                        ? { ...x, selected: true }
                        : { ...x, selected: false }
                    )
                  );
                }}
              />
            </div>
            {/* <div className="sg-max:hidden">
              <HomeTable />
            </div> */}
            <HomePacketCards />
          </div>
        </div>
      </ContainerDesktop>
    </section>
  );
};
